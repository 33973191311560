<template>
  <section class="forgotPasswordPopin">
    <v-card class="forgotPasswordPopin__card">
      <v-form ref="form" @submit.prevent="sendRecoveryRequest()" class="form">
        <div class="form-group">
          <v-card-title class="customTitle"
            >Récupération de mot de passe</v-card-title
          >
          <p>
            Si le mail fournie est connu de nos services, un email vous sera
            envoyé à l'adresse saisie pour récupérer votre mot de passe.
          </p>
          <v-text-field
            :error-messages="emailError"
            v-model="form.email"
            label="Adresse email"
            type="email"
            outlined
            clearable
            color="#2C0703"
            required
            :rules="[$rules.required, $rules.validEmail]"
          ></v-text-field>
          <div class="buttonsContainer">
            <MediumButtonSlot @click="closePopin">Annuler</MediumButtonSlot>
            <v-spacer></v-spacer>
            <HighButtonSlot :_type="'submit'">Valider</HighButtonSlot>
          </div>
        </div>
      </v-form>
    </v-card>
  </section>
</template>

<script>
export default {
  props: {
    userEmail: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      emailError: "",
      form: {
        email: this.userEmail,
      },
    };
  },
  methods: {
    closePopin() {
      this.$emit("closePopin");
    },
    sendRecoveryRequest() {
      if (this.$refs.form.validate() === false) {
        return;
      }

      this.$api.users.askRecovery(this.form.email);
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/authentication.scss";

.forgotPasswordPopin {
  @extend %popinView;

  .v-card {
    width: 33%;
  }
  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }

  .buttonsContainer button {
    margin: 5px;
  }
}
</style>
