<template>
  <div class="passwordEditView-container rightContainer">
    <div class="loginForm">
      <img class="logo" src="../../assets/images/MAORIE_RVB-low_black.png" />
      <v-form ref="form" @submit.prevent="updatePassword()" class="form">
        <div class="form-group">
          <v-text-field
            autocomplete
            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            color="#2C0703"
            required
            clearable
            v-model="form.password"
            label="Mot de passe"
            outlined
            :rules="[$rules.required, $rules.min6Chars]"
          ></v-text-field>
          <v-text-field
            autocomplete
            :append-icon="showPasswordRepeat ? 'mdi-eye-off' : 'mdi-eye'"
            :type="showPasswordRepeat ? 'text' : 'password'"
            @click:append="showPasswordRepeat = !showPasswordRepeat"
            color="#2C0703"
            required
            clearable
            v-model="form.passwordRepeat"
            label="Confirmer mot de passe"
            outlined
            :rules="[
              $rules.required,
              $rules.min6Chars,
              rules.samePassword,
            ]"
          ></v-text-field>
          <div class="loginForm__submitButton">
            <HighButtonSlot :_disabled="!isPasswordValid" _type="'submit'"
              >changer le mot de passe</HighButtonSlot
            >
          </div>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        token: "",
        password: "",
        passwordRepeat: "",
      },
      rules: {
        samePassword: (value) =>
          value === this.form.password ||
          "Les mots de passes doivent être identiques",
      },
      showPassword: false,
      showPasswordRepeat: false,
    };
  },
  methods: {
    async updatePassword() {
      await this.$api.users.resetPassword(this.form.token, this.form.password);
      this.$router.push({ name: "login" });
    },
  },
  computed: {
    isPasswordValid() {
      return this.form.password == this.form.passwordRepeat;
    },
  },
  created() {
    this.form.token = this.$route.query.token;
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/authentication.scss";

.passwordEditView-container {
  height: 100vh;
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;

  .primaryButton {
    span {
      color: white !important;
    }
  }
  .logo {
    display: block;
    width: 400px;
  }

  .loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;
    width: 80%;
    font-family: Cabin, sans-serif;
    margin-bottom: 2vh;

    .form {
      width: 80%;
    }

    h2 {
      font-weight: bold;
      font-size: 36px;
      letter-spacing: 0.25px;
      color: #2c0703;
      margin-bottom: 30px;
    }

    &__submitButton {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: white !important;

      button {
        margin-bottom: 15px;
      }
    }
    .v-btn {
      &__content {
        color: white !important;
      }
    }
  }
}
</style>
