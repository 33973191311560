<template>
  <div class="downloadView">
    <v-card>
      <v-card-title>
        {{ downloadLink.author.firstname }}
        {{ downloadLink.author.lastname }} vous a partagé des documents
      </v-card-title>
      <v-card-text>
        <v-expansion-panels v-model="isPanelOpen">
          <v-expansion-panel>
            <v-expansion-panel-header>
              Documents ajoutés ({{ downloadLink.documents.length }})
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ul>
                <li :key="file.id" v-for="file in downloadLink.documents">
                  {{ file.name }}
                </li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <HighButtonSlot @click="getZip()">telecharger</HighButtonSlot>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isPanelOpen: false,
      downloadLink: {
        documents: [],
        author: {
          firstname: "",
          lastname: "",
        },
      },
    };
  },
  methods: {
    async getDownloadLinkInfos() {
      this.downloadLink = await this.$api.documents.getDownloadLink(
        this.$route.query.token
      );
    },
    async getZip() {
      const zipContent = await this.$api.documents.downloadZipFromToken(
        this.$route.query.token
      );

      const url = window.URL.createObjectURL(new Blob([zipContent]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "export.zip");
      link.click();
    },
  },
  mounted() {
    this.getDownloadLinkInfos();
  },
};
</script>

<style lang="scss" scoped>
.downloadView {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
