<template>
  <div class="loginView-container rightContainer">
    <div class="loginForm">
      <img class="logo" src="../../assets/images/MAORIE_RVB-low_black.png" />
      <ForgotPasswordPopin
        v-if="passwordPopin.display"
        :userEmail="form.email"
        @validation="sendRecoveryRequest"
        @closePopin="passwordPopin.display = false"
      />
      <v-form ref="form" @submit.prevent="login()" class="form">
        <div class="form-group">
          <v-text-field
            autocomplete
            color="#2C0703"
            required
            v-model="form.email"
            label="Adresse mail"
            outlined
            clearable
            :loading="loading"
            :rules="[$rules.required, $rules.validEmail]"
            :validate-on-blur="true"
            placeholder=" "
            autofocus
            tabindex="1"
            persistent-placeholder
          ></v-text-field>
          <v-text-field
            autocomplete
            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            color="#2C0703"
            required
            clearable
            v-model="form.password"
            label="Mot de passe"
            outlined
            :loading="loading"
            :rules="[$rules.required]"
            placeholder=" "
            tabindex="2"
            persistent-placeholder
          ></v-text-field>
          <div class="loginForm__submitButton">
            <HighButtonSlot _type="'submit'">se connecter</HighButtonSlot>
            <a @click="passwordPopin.display = true" class="mini-link"
              >Mot de passe oublié</a
            >
          </div>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import ForgotPasswordPopin from "@/components/login/ForgotPassword.popin.vue";
import auth from "@/service/auth.js";

export default {
  name: "Login",
  components: {
    ForgotPasswordPopin,
  },
  data() {
    return {
      passwordPopin: {
        display: false,
      },
      form: {
        email: "",
        password: "",
      },
      loading: false,
      showPassword: false,
      emailError: "",
    };
  },
  methods: {
    login() {
      if (this.$refs.form.validate() === false) {
        this.$toast(
          "warning",
          "Formulaire invalide, vérifiez que l'adresse mail est bien correcte"
        );
        return;
      }

      this.loading = true;
      this.$api.authentication
        .logIn(this.form.email, this.form.password)
        .then(({ token, refresh_token }) => {
          auth.login(token, refresh_token);
          window.location.replace("/workspaces");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sendRecoveryRequest() {
      this.passwordPopin.display = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/authentication.scss";

.loginView-container {
  height: 100vh;
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;

  .primaryButton {
    span {
      color: white !important;
    }
  }
  .logo {
    display: block;
    width: 400px;
  }

  .loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;
    width: 80%;
    font-family: Cabin, sans-serif;
    margin-bottom: 2vh;

    .form {
      width: 80%;
    }

    h2 {
      font-weight: bold;
      font-size: 36px;
      letter-spacing: 0.25px;
      color: #2c0703;
      margin-bottom: 30px;
    }

    &__submitButton {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: white !important;

      button {
        margin-bottom: 15px;
      }
    }
    .v-btn {
      &__content {
        color: white !important;
      }
    }
  }
}
</style>
